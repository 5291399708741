import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// picture
import Logo from "../images/Logo.png";
import text from "../images/text.png";
import kom from "../images/kom.png";

const LandingPage = () => {
  const isMobile = useMediaQuery({ query: "(max-width:1224px)" });

  return (
    <div className={isMobile ? "d-flex flex-column justify-content-center align-items-center" : "d-flex"}>
      <Link className="d-flex justify-content-center mt-5" style={isMobile ? { width: "50%" } : { width: "50%" }}>
        <img
          style={isMobile ? { width: "100%" } : { width: "65%", height: "85%" }}
          src={isMobile ? Logo : kom}
          onClick={() => {
            // window.location.href = "https://139.162.13.30/mobile/register?ref=sport123&utm_source=sport123&utm_medium=smartbar&utm_campaign=cross_marketing";
          }}
        />
      </Link>
      <div className="d-flex flex-column justify-content-center text-center" style={isMobile ? { width: "66%" } : { width: "50%" }}>
        <Link>
          <img
            className={isMobile ? "my-3" : "me-4"}
            style={isMobile ? { width: "0%" } : { width: "30%" }}
            src={isMobile ? null : Logo}
            onClick={() => {
              // window.location.href = "https://139.162.13.30/mobile/register?ref=sport123&utm_source=sport123&utm_medium=smartbar&utm_campaign=cross_marketing";
            }}
          />
        </Link>
        <Link>
          <img
            style={isMobile ? { width: "100%" } : { width: "70%" }}
            src={text}
            onClick={() => {
              // window.location.href = "https://139.162.13.30/mobile/register?ref=sport123&utm_source=sport123&utm_medium=smartbar&utm_campaign=cross_marketing";
            }}
          />
        </Link>
      </div>
    </div>
  );
};

export default LandingPage;
